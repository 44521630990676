<template>
  <div>
    <Card>
      <template #header>İller</template>
      <div class="row">
        <div class="col-3">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
      </div>
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="İller Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
      </List>
      <b-modal v-model="addFileModal" id="note-modal">
        <template #modal-header>
          <h4>Yeni İl Ekle</h4>
        </template>

        <div class="form-group">
          <label for="aciklama">İl Adı</label>
          <input type="text" class="form-control" id="ilAdi" />
        </div>
        <div class="form-group">
          <label for="aciklama">İl Plaka Kodu</label>
          <input type="text" class="form-control" id="ilPlakaKod" />
        </div>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="addFileModal = false">
              Vazgeç
            </b-button>
            <b-button variant="success" @click="addNewNote" class="ml-4">
              Kaydet
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal v-model="addFileModal2" id="note-modal">
        <template #modal-header>
          <h4>İşlem Onayı</h4>
        </template>
        <p>
          Şehir bilgisi ve bağlı tüm ilçeler & mahalleler silinecektir! Emin
          misiniz?
        </p>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="addFileModal2 = false">
              Vazgeç
            </b-button>
            <b-button variant="danger" @click="addNewNote" class="ml-4">
              Evet
            </b-button>
          </div>
        </template>
      </b-modal>
    </Card>
  </div>
</template>
<script>
import { COUNTIES } from "../../../../core/services/store/counties.module";
import { mapGetters } from "vuex";
export default {
  name: "systemUsers",
  data() {
    return {
      addNewNote: "",
      addFileModal: false,
      addFileModal2: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İsim", value: "il_adi" },
        { text: "Şehir Kodu", value: "il_id", size: "100px" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      this.$store.dispatch(COUNTIES).then((response) => {
        this.items = response;

        // if(this.search){
        // this.items = this.items.filter((item) => {

        //    Object.keys(item).filter((prop)=>{
        //     if(item[prop].toString().toLowerCase().includes(this.item)){
        //       return true;
        //     }
        //     return false;
        //    })
        // })
        // }
        if (this.search) {
          if(this.search){
            const searchValue = this.search;
            const foundItems = this.items.filter(item => {
              for (const prop in item){
                if(item[prop].toString().toLocaleLowerCase('tr').includes(searchValue)){
                  return true;
                  
                }
              }
              return false;
            });
            this.items = foundItems
          }
        }
        this.totalItems = this.items.length;
        this.pagination = {
          current_page: 1,
          total_pages: Math.ceil(this.items.length / 10),
          total_items: this.totalItems,
        };
      });

      this.loading = false;
      // this.items = [];
      // this.totalItems = 0;
      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }
      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;
      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
};
</script>
